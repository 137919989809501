import AddTaskIcon from '@mui/icons-material/AddTask'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import CommitIcon from '@mui/icons-material/Commit'
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import LocalAtmIcon from '@mui/icons-material/LocalAtm'
import SavingsIcon from '@mui/icons-material/Savings'
import { Alert, Checkbox, MenuItem, Select, Snackbar } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Modal from '@mui/material/Modal'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import TextField from '@mui/material/TextField'
import { DateTimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import * as React from 'react'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { axiosInstance } from '../services/webClient'
import styles from '../styles/CreateEventForm.module.css'
import createStyles from '../styles/CreateEventScreen.module.css'
import goalStyles from '../styles/EventGoalsScreen.module.css'
import typeStyles from '../styles/EventTypeScreen.module.css'
import {
  BACKEND_URL,
  EVENT_TYPES,
  generateEventLink,
  matchYoutubeURL,
} from '../utils/utils'

const CreateEventPages = {
  createEvent: 'createEvent',
  eventType: 'eventType',
  goals: 'goals',
}
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

function CreateEventContainer(props) {
  const { currentEthAddress } = props
  const location = useLocation()
  const [eventInputs, setEventInputs] = useState({})

  React.useEffect(() => {
    if (location.state && location.state.eventInputs) {
      console.log('eventInputs', location.state.eventInputs)
      setEventInputs(location.state.eventInputs)
      location.state = undefined
    }
  }, [location.state])

  const [selectedEventType, setSelectedEventType] = React.useState(null)
  const updateSetEvents = (key, evt) => {
    let value = evt.target.value
    if (key === 'imgFile') {
      value = evt.target.files[0]
      setEventInputs((eventInputs) => ({ ...eventInputs, [key]: value }))
    } else {
      setEventInputs((eventInputs) => ({ ...eventInputs, [key]: value }))
    }
  }

  function clearDynamicEventUniqueFields() {
    setEventInputs((eventInputs) => ({
      ...eventInputs,
      minimumPeople: null,
    }))
    setEventInputs((eventInputs) => ({
      ...eventInputs,
      maximumPeople: null,
    }))
    setEventInputs((eventInputs) => ({
      ...eventInputs,
      goalAmount: null,
    }))
  }
  function clearStaticEventUniqueFields() {
    setEventInputs((eventInputs) => ({
      ...eventInputs,
      staticPricePerPerson: null,
    }))
  }
  function clearGeneralFundraisingEventUniqueFields() {
    setEventInputs((eventInputs) => ({
      ...eventInputs,
      goalAmount: null,
    }))
  }
  const [value, setValue] = React.useState(0)
  const [currentScreen, setCurrentScreen] = React.useState('createEvent')

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Tabs
          onChange={() => {}}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          textColor="primary"
          value={currentScreen}
          className={styles.CreateEventForm}
          sx={{
            '.MuiTabs-flexContainer': {
              borderBottom: '1px solid',
              borderColor: 'divider',
            },
            '.MuiTab-root': {
              pointerEvents: 'none', // Makes the tabs unclickable
            },
            '.Mui-selected': {
              color: 'rgba(193, 254, 175, 1) !important', // Sets the selected tab color with precedence
            },
            '& .MuiTabs-scrollButtons': {
              '&.Mui-disabled': { opacity: 0.3 },
            },
            alignSelf: 'center',
          }}
          TabIndicatorProps={{
            style: {
              backgroundColor: 'rgba(193, 254, 175, 1)',
              color: 'rgba(193, 254, 175, 1)',
            },
          }}
        >
          <Tab
            value={CreateEventPages.createEvent}
            sx={{ width: '33%', color: 'white' }}
            label="Create Campaign"
          />
          <Tab
            value={CreateEventPages.eventType}
            sx={{ width: '33%', color: 'white' }}
            label="Campaign Type"
          />
          <Tab
            value={CreateEventPages.goals}
            sx={{ width: '33%', color: 'white' }}
            label="Goals"
          />
        </Tabs>
      </Box>

      {
        <EventForm
          setCurrentScreen={setCurrentScreen}
          currentScreen={currentScreen}
          selectedEventType={selectedEventType}
          eventInputs={eventInputs}
          setEventInputs={setEventInputs}
          updateSetEvents={updateSetEvents}
          currentEthAddress={currentEthAddress}
          setSelectedEventType={setSelectedEventType}
        />
      }
    </>
  )
}

const EventForm = (props) => {
  const {
    selectedEventType,
    currentEthAddress,
    eventInputs,
    setEventInputs,
    updateSetEvents,
    setSelectedEventType,
    setCurrentScreen,
    currentScreen,
  } = props
  const [createdEvent, setCreatedEvent] = React.useState(null)
  const [open, setOpen] = React.useState(false)

  const getValidationErrors = (_eventInputs) => {
    const validationErrs = []
    if (!_eventInputs.name) {
      validationErrs.push('name')
    }
    if (!_eventInputs.eventType) {
      validationErrs.push('eventType')
    }
    if (!_eventInputs.description) {
      validationErrs.push('description')
    }
    if (!_eventInputs.deadline) {
      validationErrs.push('deadline')
    }

    if (_eventInputs.eventType === EVENT_TYPES.DYNAMIC) {
      if (!_eventInputs.minimumPeople) {
        validationErrs.push('minimumPeople')
      }
      if (!_eventInputs.goalAmount) {
        validationErrs.push('goalAmount')
      }
    } else if (_eventInputs.eventType === EVENT_TYPES.STATIC) {
      if (!_eventInputs.minimumPeople) {
        validationErrs.push('minimumPeople')
      }
      if (!_eventInputs.staticPricePerPerson) {
        validationErrs.push('staticPricePerPerson')
      }
    } else if (_eventInputs.eventType === EVENT_TYPES.SPECIFIC_FUNDRAISING) {
      if (!_eventInputs.goalAmount) {
        validationErrs.push('goalAmount')
      }
    }
    if (
      _eventInputs.maximumPeople &&
      _eventInputs.minimumPeople &&
      _eventInputs.maximumPeople < _eventInputs.minimumPeople
    ) {
      validationErrs.push('maxPeople less than minPeople')
    }
    return validationErrs
  }

  const uploadimgFileToS3 = async (presignedUrl, imgFile) => {
    axiosInstance
      .put(presignedUrl, imgFile, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: false,
        transformRequest: [
          (data, headers) => {
            delete headers.Authorization
            return data
          },
        ],
      })
      .then((response) => {
        console.log('Upload successful')
      })
      .catch((error) => console.error('Error uploading file:', error))
  }

  const submitEvent = async (eventInputs) => {
    console.log('submitEvent entrance', { eventInputs, currentEthAddress })
    return await fetch(BACKEND_URL + '/event/submitEvent', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        event: {
          name: eventInputs.name,
          description: eventInputs.description,
          deadline: eventInputs.deadline,
          youtubeLink: eventInputs.youtubeLink,
          minimumPeople: eventInputs.minimumPeople,
          maximumPeople: eventInputs.maximumPeople,
          isCreatorAttendee: eventInputs.isCreatorAttendee ? true : false,
          goalAmount: eventInputs.goalAmount,
          staticPricePerPerson: eventInputs.staticPricePerPerson,
          eventType: eventInputs.eventType,
          creatorEthAddress: currentEthAddress,
          file: eventInputs.imgFile ? true : false,
        },
      }),
      credentials: 'include',
    })
      .then(async function (response) {
        const { savedEvent, presignedUrl } = await response.json()
        console.log('submitEvent response', { savedEvent, response })
        if (eventInputs.imgFile) {
          console.log('uploading img', {
            img: eventInputs.imgFile,
            presignedUrl,
          })
          await uploadimgFileToS3(presignedUrl, eventInputs.imgFile)
        }
        setEventInputs({
          name: '',
          eventType: '',
          description: '',
          deadline: '',
          currentEthAddress: '',
          goalAmount: '',
          minimumPeople: '',
          maximumPeople: '',
          staticPricePerPerson: '',
          imgFile: null,
        })
        setOpen(true)
        console.log('submitEvent exit')
        setCreatedEvent(savedEvent)
        return savedEvent
      })
      .catch(function (error) {
        console.log('submitEvent', error)
      })
  }

  const areRequiredInputsSet = (currentScreen, inputs) => {
    // TODO: MOOSE - implement the requirements check
    return true
  }
  const canShowBackButton = (currentScreen) => {
    return [CreateEventPages.eventType, CreateEventPages.goals].includes(
      currentScreen
    )
  }

  const [openErrorSnackBar, setOpenErrorSnackBar] = React.useState(false)
  const [snackBarErrorMsg, setSnackBarErrorMsg] = React.useState(false)
  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: '100%', width: '100%' }}
      >
        <Grid item style={{ margin: 0, width: '100%' }}>
          <Stack spacing={2} direction="column">
            {(function generateCreateEventScreens() {
              switch (currentScreen) {
                case 'createEvent':
                  return (
                    <CreateEventScreen
                      setCurrentScreen={setCurrentScreen}
                      eventInputs={eventInputs}
                      setEventInputs={setEventInputs}
                      updateSetEvents={updateSetEvents}
                    />
                  )
                case 'eventType':
                  return (
                    <EventTypeScreen
                      setEventInputs={setEventInputs}
                      setCurrentScreen={setCurrentScreen}
                      updateSetEvents={updateSetEvents}
                      eventInputs={eventInputs}
                    />
                  )
                case 'goals':
                  return (
                    <GoalsScreen
                      eventInputs={eventInputs}
                      setCurrentScreen={setCurrentScreen}
                      submitEvent={submitEvent}
                      updateSetEvents={updateSetEvents}
                    />
                  )
                default:
                  break
              }
            })()}
          </Stack>
        </Grid>
      </Box>

      <EventCreatedModal
        open={open}
        handleClose={() => setOpen(false)}
        createdEvent={createdEvent}
        setSelectedEventType={setSelectedEventType}
      />
      <Snackbar
        open={openErrorSnackBar}
        autoHideDuration={5000}
        onClose={() => setOpenErrorSnackBar(false)}
      >
        <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
          Invalid Input: {snackBarErrorMsg}{' '}
          {/* <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setOpenErrorSnackBar(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton> */}
        </Alert>
      </Snackbar>
    </>
  )
}

const CurrencyDenomination = (props) => {
  const [currency, setCurrency] = React.useState('USD')

  return (
    <Stack direction="row" spacing={2} alignItems={'center'}>
      <Select
        value={currency}
        onChange={(evt) => setCurrency(evt.target.value)}
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: '0px 1px 0px 0px !important',
            borderRadius: '0px !important',
          },
          '& .MuiSelect-select': {
            '-webkit-text-fill-color': '#829aff !important',
            // border: '1px solid #a2a3a9 !important',
            margin: '0 !important',
            padding: '0px 30px 0px 0px !important',
          },
          '& .MuiSelect-icon': {
            color: '#a2a3a9 !important',
          },
          // 'input::placeholder': {
          //   color: '#cac4d0 !important',
          //   opacity: 1,
          // },
          // "& .MuiOutlinedInput-root": {
          //   "&.Mui-focused fieldset": {
          //     borderColor: '#a2a3a9',
          //   }
          // }
        }}
      >
        <MenuItem value={'USD'}>USD</MenuItem>
      </Select>
      <div>$</div>
    </Stack>
  )
}

const validateNumericalInput = (evt) => {
  return (
    (!Number.isNaN(Number(evt.target.value)) &&
      evt.target.value.slice(-1) !== '.' &&
      evt.target.value[0] !== '0' &&
      evt.target.value.slice(-1) !== '-' &&
      evt.target.value.slice(-1) !== ' ' &&
      evt.target.value[0] !== '-') ||
    evt.target.value === ''
  )
}

const DynamicEventGoals = (props) => {
  const { updateSetEvents, eventInputs } = props

  return (
    <>
      <Stack direction={'column'} sx={{ width: '100%' }} spacing={2}>
        <Stack
          direction={{ xs: 'column' }}
          spacing={{ xs: 1 }}
          className={createStyles.CreateEventInfo}
        >
          <div className={createStyles.CreateEventInfoLabel}>
            Minimum Attendees
          </div>
          <TextField
            name="minimum-attendees-dynamic"
            placeholder={''}
            autoComplete="off"
            value={eventInputs?.minimumPeople}
            error={
              eventInputs?.minimumPeople === '' ||
              (Number(eventInputs?.minimumPeople) === 1 &&
                eventInputs.isCreatorAttendee)
            }
            helperText={
              eventInputs?.minimumPeople === ''
                ? 'Minimum number of people is required'
                : Number(eventInputs?.minimumPeople) === 1 &&
                  eventInputs.isCreatorAttendee
                ? 'Minimum number of people must be greater than 1'
                : null
            }
            onChange={(evt) => {
              if (validateNumericalInput(evt)) {
                updateSetEvents('minimumPeople', evt)
              }
            }}
            sx={{
              '& .MuiInputBase-root': {
                color: '#a2a3a9',
              },
              '& .MuiInputBase-input': {
                '-webkit-text-fill-color': '#a2a3a9 !important',
                // border: '1px solid #a2a3a9 !important',
              },
              'input::placeholder': {
                color: '#cac4d0 !important',
                opacity: 1,
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#a2a3a9',
                },
                '&:hover fieldset': {
                  borderColor: '#a2a3a9',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#a2a3a9',
                },
                '&.Mui-error fieldset': {
                  borderColor: '#d32f2f',
                },
              },
            }}
          />
        </Stack>
        <Stack direction={'column'} spacing={0}>
          <Stack
            direction={'row'}
            spacing={1}
            alignItems={'center'}
            alignSelf={'center'}
          >
            <Checkbox
              checked={eventInputs.isCreatorAttendee}
              onChange={() =>
                updateSetEvents('isCreatorAttendee', {
                  target: { value: !eventInputs.isCreatorAttendee },
                })
              }
              sx={{
                color: '#a2a3a9',
                '&.Mui-checked': {
                  color: '#829aff',
                },
              }}
            />
            <div
              style={{
                fontFamily: 'Arial',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '16.1px',
                textAlign: 'left',
                color: '#a2a3a9',
              }}
            >
              Include myself in split cost calculation
            </div>
          </Stack>
          <div
            style={{
              fontFamily: 'Arial',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '16.1px',
              textAlign: 'left',
              color: 'rgba(119, 119, 119, 1)',
              alignSelf: 'center',
            }}
          >
            If checked, the total cost is split between all attendees including
            yourself.
          </div>
        </Stack>
        <Stack
          direction={{ xs: 'column' }}
          spacing={{ xs: 1 }}
          className={createStyles.CreateEventInfo}
        >
          <div className={createStyles.CreateEventInfoLabel}>
            Maximum Attendees (optional)
          </div>
          <TextField
            placeholder={''}
            autoComplete="off"
            value={eventInputs?.maximumPeople}
            onChange={(evt) => {
              if (validateNumericalInput(evt)) {
                updateSetEvents('maximumPeople', evt)
              }
            }}
            id="max-attendees"
            sx={{
              '& .MuiInputBase-root': {
                color: '#a2a3a9',
              },
              '& .MuiInputBase-input': {
                '-webkit-text-fill-color': '#a2a3a9 !important',
                // border: '1px solid #a2a3a9 !important',
              },
              'input::placeholder': {
                color: '#cac4d0 !important',
                opacity: 1,
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: '#a2a3a9',
                },
                '& fieldset': {
                  borderColor: '#a2a3a9 !important',
                },
                '&:hover fieldset': {
                  borderColor: '#a2a3a9',
                },
              },
            }}
          />
        </Stack>

        <Stack
          direction={{ xs: 'column' }}
          spacing={{ xs: 1 }}
          className={createStyles.CreateEventInfo}
        >
          <div className={createStyles.CreateEventInfoLabel}>Total Amount</div>
          <TextField
            placeholder={''}
            autoComplete="off"
            value={eventInputs?.goalAmount}
            error={eventInputs?.goalAmount === ''}
            helperText={
              eventInputs?.goalAmount === ''
                ? 'Minimum amount is required'
                : null
            }
            onChange={(evt) => {
              if (validateNumericalInput(evt)) {
                updateSetEvents('goalAmount', evt)
              }
            }}
            sx={{
              '& .MuiInputBase-root': {
                color: '#a2a3a9',
              },
              '& .MuiInputBase-input': {
                '-webkit-text-fill-color': '#a2a3a9 !important',
                // border: '1px solid #a2a3a9 !important',
              },
              'input::placeholder': {
                color: '#cac4d0 !important',
                opacity: 1,
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#a2a3a9',
                },
                '&:hover fieldset': {
                  borderColor: '#a2a3a9',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#a2a3a9',
                },
                '&.Mui-error fieldset': {
                  borderColor: '#d32f2f',
                },
              },
            }}
            InputProps={{
              startAdornment: <CurrencyDenomination />,
            }}
          />
        </Stack>
        <div
          style={{
            fontFamily: 'Arial',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '16.1px',
            // textAlign: 'left',
            color: 'rgba(119, 119, 119, 1)',
            alignSelf: 'center',
          }}
        >
          Cost per person changes based on the number of attendees.
        </div>

        <Stack
          direction={{ xs: 'column' }}
          spacing={{ xs: 1 }}
          className={createStyles.CreateEventInfo}
        >
          <div className={createStyles.CreateEventInfoLabel}>Summary</div>
          <div
            style={{
              fontFamily: 'Arial',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '16.1px',
              // textAlign: 'left',
              color: 'rgba(119, 119, 119, 1)',
              alignSelf: 'center',
            }}
          >
            {`Based on your goal of $`}
            <span style={{ fontWeight: 'bold' }}>
              {eventInputs?.goalAmount}
            </span>
            {` and a minimum attendance of `}
            <span style={{ fontWeight: 'bold' }}>
              {eventInputs?.isCreatorAttendee
                ? eventInputs?.minimumPeople + 1
                : eventInputs?.minimumPeople}
            </span>
            {` people required to tip, the highest possible price per person will be $`}
            <span style={{ fontWeight: 'bold' }}>
              {(eventInputs?.goalAmount /
                (eventInputs?.isCreatorAttendee
                  ? eventInputs?.minimumPeople + 1
                  : eventInputs?.minimumPeople
                )).toFixed(2)}
            </span>
            {`. 
              The price will fall as more people opt in. `}
            {eventInputs?.maximumPeople &&
              `If your maximum attendance is reached, the price per person will be $`}
            <span style={{ fontWeight: 'bold' }}>
              {eventInputs?.maximumPeople &&
                (eventInputs?.goalAmount / eventInputs?.maximumPeople).toFixed(
                  2
                )}
            </span>
          </div>
        </Stack>
      </Stack>
    </>
  )
}

const StaticEventGoals = (props) => {
  const { updateSetEvents, eventInputs } = props
  return (
    <>
      <Stack direction={'column'} sx={{ width: '100%' }} spacing={2}>
        <Stack
          direction={{ xs: 'column' }}
          spacing={{ xs: 1 }}
          className={createStyles.CreateEventInfo}
        >
          <div className={createStyles.CreateEventInfoLabel}>
            Minimum Attendees
          </div>
          <TextField
            placeholder={''}
            autoComplete="off"
            value={eventInputs?.minimumPeople}
            error={eventInputs?.minimumPeople === ''}
            helperText={
              eventInputs?.minimumPeople === ''
                ? 'Minimum number of people is required'
                : null
            }
            onChange={(evt) => {
              if (validateNumericalInput(evt))
                updateSetEvents('minimumPeople', evt)
            }}
            sx={{
              '& .MuiInputBase-root': {
                color: '#a2a3a9',
              },
              '& .MuiInputBase-input': {
                '-webkit-text-fill-color': '#a2a3a9 !important',
                // border: '1px solid #a2a3a9 !important',
              },
              'input::placeholder': {
                color: '#cac4d0 !important',
                opacity: 1,
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#a2a3a9',
                },
                '&:hover fieldset': {
                  borderColor: '#a2a3a9',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#a2a3a9',
                },
                '&.Mui-error fieldset': {
                  borderColor: '#d32f2f',
                },
              },
            }}
          />
        </Stack>

        <Stack
          direction={{ xs: 'column' }}
          spacing={{ xs: 1 }}
          className={createStyles.CreateEventInfo}
        >
          <div className={createStyles.CreateEventInfoLabel}>
            Maximum Attendees (optional)
          </div>
          <TextField
            placeholder={''}
            autoComplete="off"
            value={eventInputs?.maximumPeople}
            onChange={(evt) => {
              if (validateNumericalInput(evt))
                updateSetEvents('maximumPeople', evt)
            }}
            sx={{
              '& .MuiInputBase-root': {
                color: '#a2a3a9',
              },
              '& .MuiInputBase-input': {
                '-webkit-text-fill-color': '#a2a3a9 !important',
                // border: '1px solid #a2a3a9 !important',
              },
              'input::placeholder': {
                color: '#cac4d0 !important',
                opacity: 1,
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: '#a2a3a9',
                },
                '& fieldset': {
                  borderColor: '#a2a3a9 !important',
                },
                '&:hover fieldset': {
                  borderColor: '#a2a3a9',
                },
              },
            }}
          />
        </Stack>
        <div
          style={{
            fontFamily: 'Arial',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '16.1px',
            textAlign: 'left',
            color: 'rgba(119, 119, 119, 1)',
          }}
        >
          Set a fixed price per attendee.
        </div>
        <Stack
          direction={{ xs: 'column' }}
          spacing={{ xs: 1 }}
          className={createStyles.CreateEventInfo}
        >
          <div className={createStyles.CreateEventInfoLabel}>
            Price Per Person
          </div>
          <TextField
            placeholder={''}
            autoComplete="off"
            value={eventInputs?.staticPricePerPerson}
            error={eventInputs?.staticPricePerPerson === ''}
            helperText={
              eventInputs?.staticPricePerPerson === ''
                ? 'Price per person is required'
                : null
            }
            onChange={(evt) => {
              if (validateNumericalInput(evt))
                updateSetEvents('staticPricePerPerson', evt)
            }}
            sx={{
              '& .MuiInputBase-root': {
                color: '#a2a3a9',
              },
              '& .MuiInputBase-input': {
                '-webkit-text-fill-color': '#a2a3a9 !important',
                // border: '1px solid #a2a3a9 !important',
              },
              'input::placeholder': {
                color: '#cac4d0 !important',
                opacity: 1,
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#a2a3a9',
                },
                '&:hover fieldset': {
                  borderColor: '#a2a3a9',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#a2a3a9',
                },
                '&.Mui-error fieldset': {
                  borderColor: '#d32f2f',
                },
              },
            }}
            InputProps={{
              startAdornment: <CurrencyDenomination />,
            }}
          />
        </Stack>
      </Stack>
    </>
  )
}

const SpecificFundraisingEventGoals = (props) => {
  const { updateSetEvents, eventInputs } = props

  return (
    <>
      <Stack
        direction={{ xs: 'column' }}
        spacing={{ xs: 1 }}
        sx={{ width: '100%' }}
      >
        <div className={createStyles.CreateEventInfoLabel}>Minimum Amount</div>
        <TextField
          placeholder={''}
          autoComplete="off"
          id="specific-fundraising-minimum-amount"
          value={eventInputs?.goalAmount}
          error={eventInputs?.goalAmount === ''}
          helperText={
            eventInputs?.goalAmount === '' ? 'Minimum amount is required' : null
          }
          onChange={(evt) => {
            if (validateNumericalInput(evt)) updateSetEvents('goalAmount', evt)
          }}
          sx={{
            '& .MuiInputBase-root': {
              color: '#a2a3a9',
            },
            '& .MuiInputBase-input': {
              '-webkit-text-fill-color': '#a2a3a9 !important',
              // border: '1px solid #a2a3a9 !important',
            },
            'input::placeholder': {
              color: '#cac4d0 !important',
              opacity: 1,
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#a2a3a9',
              },
              '&:hover fieldset': {
                borderColor: '#a2a3a9',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#a2a3a9',
              },
              '&.Mui-error fieldset': {
                borderColor: '#d32f2f',
              },
            },
          }}
          InputProps={{
            startAdornment: <CurrencyDenomination />,
          }}
        />
      </Stack>
    </>
  )
}

const GeneralFundraisingEventGoals = (props) => {
  const { updateSetEvents, eventInputs } = props

  return (
    <>
      <Stack
        direction={{ xs: 'column' }}
        spacing={{ xs: 1 }}
        sx={{ width: '100%' }}
      >
        <div className={createStyles.CreateEventInfoLabel}>
          Goal Amount (optional)
        </div>
        <TextField
          placeholder={''}
          autoComplete="off"
          value={eventInputs?.goalAmount}
          id="goal-amount-general-fundraising"
          onChange={(evt) => {
            if (validateNumericalInput(evt)) updateSetEvents('goalAmount', evt)
          }}
          sx={{
            '& .MuiInputBase-root': {
              color: '#a2a3a9',
            },
            '& .MuiInputBase-input': {
              '-webkit-text-fill-color': '#a2a3a9 !important',
              // border: '1px solid #a2a3a9 !important',
            },
            'input::placeholder': {
              color: '#cac4d0 !important',
              opacity: 1,
            },
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: '#a2a3a9',
              },
              '& fieldset': {
                borderColor: '#a2a3a9 !important',
              },
              '&:hover fieldset': {
                borderColor: '#a2a3a9',
              },
            },
          }}
          InputProps={{
            startAdornment: <CurrencyDenomination />,
          }}
        />
      </Stack>
    </>
  )
}

const validateEventGoals = (eventInputs, updateSetEvents) => {
  let errMsg = null
  switch (eventInputs.eventType) {
    case EVENT_TYPES.DYNAMIC:
      if (!eventInputs.minimumPeople) {
        updateSetEvents('minimumPeople', { target: { value: '' } })
        errMsg = 'Minimum number of people is required'
      }
      if (!eventInputs.goalAmount) {
        updateSetEvents('goalAmount', { target: { value: '' } })
        errMsg = errMsg ? errMsg : 'Minimum amount is required'
      }
      if (
        eventInputs.isCreatorAttendee &&
        Number(eventInputs.minimumPeople) === 1
      ) {
        errMsg = errMsg
          ? errMsg
          : 'Minimum number of people must be greater than 1'
      }
      if (
        eventInputs.maximumPeople &&
        eventInputs.minimumPeople &&
        Number(eventInputs.maximumPeople) < Number(eventInputs.minimumPeople)
      ) {
        errMsg = errMsg
          ? errMsg
          : 'Maximum number of people must be greater than minimum number of people'
      }
      return errMsg
    case EVENT_TYPES.STATIC:
      if (!eventInputs.minimumPeople) {
        updateSetEvents('minimumPeople', { target: { value: '' } })
        errMsg = 'Minimum number of people is required'
      }
      if (!eventInputs.staticPricePerPerson) {
        updateSetEvents('staticPricePerPerson', { target: { value: '' } })
        errMsg = errMsg ? errMsg : 'Price per person is required'
      }
      if (
        eventInputs.maximumPeople &&
        eventInputs.minimumPeople &&
        Number(eventInputs.maximumPeople) < Number(eventInputs.minimumPeople)
      ) {
        errMsg = errMsg
          ? errMsg
          : 'Maximum number of people must be greater than minimum number of people'
      }
      return errMsg
    case EVENT_TYPES.SPECIFIC_FUNDRAISING:
      if (!eventInputs.goalAmount) {
        updateSetEvents('goalAmount', { target: { value: '' } })
        return 'Goal amount is required'
      }
    case EVENT_TYPES.GENERAL_FUNDRAISING:
      return null
    default:
      return null
  }
}

const CreateEventScreen = (props) => {
  const { eventInputs, setEventInputs, updateSetEvents, setCurrentScreen } =
    props

  const [openSnackBar, setOpenSnackBar] = React.useState(false)
  const [deadlineValid, setDeadlineValid] = React.useState(true)
  const [eventNameError, setEventNameError] = React.useState(null)
  const [eventDescriptionError, setEventDescriptionError] = React.useState(null)
  const [eventYTLinkError, setEventYTLinkError] = React.useState(null)
  const [eventYTLinkDisplay, setEventYTLinkDisplay] = React.useState(null)

  const handleCreateEventScreenSubmit = () => {
    if (
      !eventInputs.name ||
      eventNameError ||
      !eventInputs.description ||
      eventDescriptionError ||
      !eventInputs.deadline ||
      eventYTLinkError
    ) {
      if (!eventInputs.name) {
        setEventNameError('Campaign name is required')
      }
      if (!eventInputs.description) {
        setEventDescriptionError('Campaign description is required')
      }
      if (!eventInputs.deadline) {
        setDeadlineValid(false)
      }
      setOpenSnackBar(true)
    } else setCurrentScreen(CreateEventPages.eventType)
  }

  return (
    <>
      <Stack
        direction="column"
        spacing={{ xs: 2 }}
        className={styles.CreateEventScreen}
      >
        <Stack
          direction={{ xs: 'column' }}
          spacing={{ xs: 1 }}
          className={createStyles.CreateEventInfo}
        >
          <div className={createStyles.CreateEventInfoLabel}>
            Create a Campaign Name
          </div>
          <TextField
            required
            autoComplete="off"
            error={eventNameError ? true : false}
            helperText={eventNameError}
            placeholder={'Campaign Name *'}
            value={eventInputs?.name}
            onChange={(evt) => {
              if (evt.target.value === '') {
                setEventNameError('Campaign name is required')
              } else if (evt.target.value.length > 100) {
                setEventNameError(
                  'Campaign name must be at most 100 characters'
                )
              } else {
                setEventNameError(null)
              }
              updateSetEvents('name', evt)
            }}
            sx={{
              '& .MuiInputBase-root': {
                color: '#a2a3a9',
              },
              '& .MuiInputBase-input': {
                '-webkit-text-fill-color': '#a2a3a9 ',
                // border: '1px solid #a2a3a9 !important',
              },
              '& input::placeholder': {
                color: '#cac4d0',
                opacity: 1,
              },
              // "& .MuiOutlinedInput-root": {
              //   '& .Mui-error fieldset': {
              //     borderColor: '#d32f2f',
              //   },
              //   '& .Mui-focused fieldset': {
              //     borderColor: '#a2a3a9 !important',
              //   },
              // },
              '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                  borderColor: '#a2a3a9',
                },
                '& fieldset': {
                  borderColor: '#a2a3a9',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#a2a3a9',
                },
                '&.Mui-error fieldset': {
                  borderColor: '#d32f2f',
                },
              },
            }}
          />
        </Stack>
        <Stack
          direction={{ xs: 'column' }}
          spacing={{ xs: 1 }}
          className={createStyles.CreateEventInfo}
        >
          <div className={createStyles.CreateEventInfoLabel}>
            Describe your Campaign
          </div>
          <TextField
            required
            autoComplete="off"
            error={eventDescriptionError ? true : false}
            helperText={eventDescriptionError}
            multiline={true}
            minRows={3}
            maxRows={7}
            placeholder={'Description *'}
            value={eventInputs?.description}
            onChange={(evt) => {
              if (evt.target.value === '') {
                setEventDescriptionError('Campaign description is required')
              } else if (evt.target.value.length > 1000) {
                setEventDescriptionError(
                  'Campaign description must be at most 1200 characters'
                )
              } else {
                setEventDescriptionError(null)
              }
              updateSetEvents('description', evt)
            }}
            sx={{
              '& .MuiInputBase-root': {
                color: '#a2a3a9',
              },
              '& .MuiInputBase-input': {
                '-webkit-text-fill-color': '#a2a3a9 !important',
                // border: '1px solid #a2a3a9 !important',
              },
              'textarea::placeholder': {
                color: '#cac4d0 !important',
                opacity: 1,
              },
              // 'textarea:hover': {
              //   borderColor: '#cac4d0 !important',
              //   opacity: 1,
              // },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#a2a3a9',
                },
                '&:hover fieldset': {
                  borderColor: '#a2a3a9',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#a2a3a9',
                },
                '&.Mui-error fieldset': {
                  borderColor: '#d32f2f',
                },
              },
            }}
          />
        </Stack>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <label
            className={createStyles.CreateEventInfoLabel}
            style={{ marginBottom: '5px' }}
            htmlFor="event-name"
          >
            Deadline
          </label>
          {
            <DateTimePicker
              value={
                eventInputs?.deadline ? dayjs(eventInputs?.deadline) : null
              }
              labelId="deadline-label"
              id="deadline"
              fullWidth={true}
              disablePast
              views={
                process.env.REACT_APP_ENVIRONMENT === 'production'
                  ? ['year', 'month', 'day', 'hours']
                  : ['year', 'month', 'day', 'hours', 'minutes']
              }
              onChange={(date) => {
                setDeadlineValid(date)
                setEventInputs((eventInputs) => ({
                  ...eventInputs,
                  deadline: date,
                }))
              }}
              slotProps={{
                textField: {
                  helperText: !deadlineValid ? 'Invalid Deadline' : '',
                  error: !deadlineValid,
                  placeholder: 'Deadline *',
                },
              }}
              onClose={() => {
                if (!eventInputs?.deadline) {
                  setDeadlineValid(false)
                }
                if (dayjs(eventInputs.deadline).isBefore(dayjs())) {
                  setDeadlineValid(false)
                }
              }}
              sx={{
                '& label': { color: '#a2a3a9' },
                '& input': { color: '#a2a3a9' },
                '& button': { color: '#c1feaf' },
                '& input::placeholder': {
                  color: '#a2a3a9 !important',
                  opacity: 1,
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#a2a3a9',
                  },
                  '&:hover fieldset': {
                    borderColor: '#a2a3a9',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#a2a3a9',
                  },
                  '&.Mui-error fieldset': {
                    borderColor: '#d32f2f',
                  },
                },
                borderRadius: '5px',
                width: '100%',
              }}
              InputLabelProps={{
                shrink: false,
              }}
            />
          }
        </div>
        <Stack
          direction={{ xs: 'column' }}
          spacing={{ xs: 1 }}
          className={createStyles.CreateEventInfo}
        >
          <div className={createStyles.CreateEventInfoLabel}>
            Youtube Link (optional)
          </div>
          <TextField
            required
            autoComplete="off"
            error={eventYTLinkError ? true : false}
            helperText={eventYTLinkError}
            placeholder={'Paste Link '}
            value={eventYTLinkDisplay}
            onChange={(evt) => {
              if (evt.target.value === '') {
                setEventYTLinkError(null)
                setEventYTLinkDisplay('')
                updateSetEvents('youtubeLink', {
                  target: { value: undefined },
                })
                return
              }
              try {
                const ytVideoCode = matchYoutubeURL(evt.target.value)
                updateSetEvents('youtubeLink', {
                  target: { value: ytVideoCode },
                })
                setEventYTLinkError(null)
              } catch (error) {}
              setEventYTLinkDisplay(evt.target.value)
            }}
            onBlur={(evt) => {
              if (evt.target.value === '') {
                setEventYTLinkError(null)
                return
              }
              try {
                matchYoutubeURL(evt.target.value)
                setEventYTLinkError(null)
              } catch (error) {
                setEventYTLinkError('Invalid Youtube Link')
              }
            }}
            sx={{
              '& .MuiInputBase-root': {
                color: '#a2a3a9',
              },
              '& .MuiInputBase-input': {
                '-webkit-text-fill-color': '#a2a3a9 !important',
                // border: '1px solid #a2a3a9 !important',
              },
              'input::placeholder': {
                color: '#cac4d0 !important',
                opacity: 1,
              },
              // 'textarea:hover': {
              //   borderColor: '#cac4d0 !important',
              //   opacity: 1,
              // },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#a2a3a9',
                },
                '&:hover fieldset': {
                  borderColor: '#a2a3a9',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#a2a3a9',
                },
                '&.Mui-error fieldset': {
                  borderColor: '#d32f2f',
                },
              },
            }}
          />
        </Stack>
        {/* <Stack
          direction={{ xs: 'column' }}
          spacing={{ xs: 1 }}
          className={createStyles.CreateEventInfo}
        >
          <div className={createStyles.CreateEventInfoLabel}>
            Invitees [Coming in v2]
          </div>
          <TextField
            disabled
            placeholder={'[Coming in v2]'}
            onChange={(evt) => updateSetEvents('description', evt)}
            sx={{
              '& .MuiInputBase-root': {
                color: '#a2a3a9',
              },
              '& .MuiInputBase-input': {
                '-webkit-text-fill-color': '#a2a3a9 !important',
                // border: '1px solid #a2a3a9 !important',
              },
              'textarea::placeholder': {
                color: '#cac4d0 !important',
                opacity: 1,
              },
              // 'textarea:hover': {
              //   borderColor: '#cac4d0 !important',
              //   opacity: 1,
              // },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: '#a2a3a9',
                },
                '& fieldset': {
                  borderColor: '#434343 !important',
                },
              },
            }}
          />
        </Stack> */}
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
          style={{
            color: 'rgba(193, 254, 175, 1)',
            backgroundColor: '#2E2E2E',
            borderRadius: '14px',
            borderColor: 'rgba(193, 254, 175, 1)', // Changed border color to match the specified rgba value
            borderStyle: 'solid', // Added border style to ensure the border is visible
            borderWidth: 'thin', // Specified the border width as thin
            textTransform: 'capitalize',
            padding: '10px 20px',
            width: '250px',
            alignSelf: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <div>Upload image (optional)</div>
            <div
              style={{
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '20.46px',
                textAlign: 'left',
                color: 'rgba(255, 255, 255, 1)',
              }}
            >
              PDF, JPEG or PNG less than 5MB
            </div>
          </div>
          <VisuallyHiddenInput
            type="file"
            onChange={(evt) => updateSetEvents('imgFile', evt)}
          />
        </Button>
        {eventInputs?.imgFile && (
          <TextField
            disabled
            label="File selected"
            defaultValue={eventInputs.imgFile.name}
            variant="outlined"
            sx={{
              '& .MuiInputBase-root': {
                color: '#a2a3a9',
              },
              '& .MuiInputBase-input': {
                '-webkit-text-fill-color': '#a2a3a9 !important',
                // border: '1px solid #a2a3a9 !important',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#a2a3a9 !important',
                },
                '&:hover fieldset': {
                  borderColor: '#a2a3a9 !important',
                },
              },
              '& .MuiInputLabel-root': {
                color: '#a2a3a9 !important',
              },
            }}
          />
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={handleCreateEventScreenSubmit}
            // disabled
            variant="contained"
            sx={{
              mt: 1,
              color: '#f1f1f1',
              backgroundColor: '#2a4fff',
              textTransform: 'capitalize',
              borderRadius: '10px',
              width: '116px',
              height: '45px',
            }}
          >
            Next
          </Button>
        </div>
      </Stack>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert
          severity="error"
          onClose={() => setOpenSnackBar(false)}
          variant="filled"
        >
          {eventNameError
            ? eventNameError
            : eventDescriptionError
            ? eventDescriptionError
            : !deadlineValid
            ? 'Deadline is required'
            : eventYTLinkError
            ? eventYTLinkError
            : 'Invalid Input: Please fill out all required fields'}
        </Alert>
      </Snackbar>
    </>
  )
}

const EventTypeScreen = (props) => {
  const { setEventInputs, setCurrentScreen, eventInputs } = props
  const activeButtonInitial =
    eventInputs?.eventType === EVENT_TYPES.DYNAMIC
      ? 1
      : eventInputs?.eventType === EVENT_TYPES.SPECIFIC_FUNDRAISING
      ? 2
      : eventInputs?.eventType === EVENT_TYPES.GENERAL_FUNDRAISING
      ? 3
      : eventInputs?.eventType === EVENT_TYPES.STATIC
      ? 4
      : null
  const [activeButton, setActiveButton] = useState(activeButtonInitial)

  const [openSnackBar, setOpenSnackBar] = React.useState(false)

  return (
    <Stack
      direction="column"
      spacing={{ xs: 2 }}
      alignItems={'center'}
      className={styles.CreateEventForm}
      sx={{ alignSelf: 'center' }}
    >
      <div
        style={{
          fontSize: '20px',
          fontWeight: '600',
          lineHeight: '24px',
          color: 'rgba(193, 254, 175, 1)',
          margin: '15px 0px',
          textAlign: 'left',
          alignSelf: 'flex-start',
          position: 'relative',
          // left: '10%',
        }}
      >
        Select Campaign Type
      </div>
      <Stack
        spacing={1}
        direction={{ xs: 'column' }}
        className={typeStyles.EventTypeContainer}
      >
        <Stack
          spacing={1}
          direction="row"
          className={
            !activeButton || activeButton == 1
              ? typeStyles.EventTypeDynamicContainer
              : typeStyles.EventTypeGreyContainer
          }
          onClick={() => {
            console.log('clicked')
            setEventInputs((eventInputs) => ({
              ...eventInputs,
              eventType: EVENT_TYPES.DYNAMIC,
            }))
            setActiveButton(1)
          }}
        >
          <Stack
            direction={'column'}
            spacing={1}
            className={
              !activeButton || activeButton == 1
                ? typeStyles.EventTypeDynamicTextContainer
                : typeStyles.EventTypeGreyTextContainer
            }
          >
            <div
              className={
                !activeButton || activeButton == 1
                  ? typeStyles.EventTypeDynamicName
                  : typeStyles.EventTypeGreyName
              }
            >
              Split Fixed Cost
            </div>
            <div
              className={
                !activeButton || activeButton == 1
                  ? typeStyles.EventTypeDynamicDescription
                  : typeStyles.EventTypeGreyDescription
              }
            >
              Price per person changes based on attendance
            </div>
          </Stack>
          <CommitIcon
            className={
              !activeButton || activeButton == 1
                ? typeStyles.EventTypeDynamicIcon
                : typeStyles.EventTypeGreyIcon
            }
          />
        </Stack>
        <Stack
          spacing={1}
          direction="row"
          className={
            !activeButton || activeButton == 4
              ? typeStyles.EventTypeStaticContainer
              : typeStyles.EventTypeGreyContainer
          }
          onClick={() => {
            setEventInputs((eventInputs) => ({
              ...eventInputs,
              eventType: EVENT_TYPES.STATIC,
            }))
            setActiveButton(4)
          }}
        >
          <Stack
            direction={'column'}
            spacing={1}
            className={
              !activeButton || activeButton == 4
                ? typeStyles.EventTypeStaticTextContainer
                : typeStyles.EventTypeGreyTextContainer
            }
          >
            <div
              className={
                !activeButton || activeButton == 4
                  ? typeStyles.EventTypeStaticName
                  : typeStyles.EventTypeGreyName
              }
            >
              Fixed Price Per Person
            </div>
            <div
              className={
                !activeButton || activeButton == 4
                  ? typeStyles.EventTypeStaticDescription
                  : typeStyles.EventTypeGreyDescription
              }
            >
              Price per person is known from the start
            </div>
          </Stack>
          <ConfirmationNumberIcon
            className={
              !activeButton || activeButton == 4
                ? typeStyles.EventTypeStaticIcon
                : typeStyles.EventTypeGreyIcon
            }
          />
        </Stack>
        <Stack
          spacing={1}
          direction="row"
          className={
            !activeButton || activeButton == 2
              ? typeStyles.EventTypeFundContainer
              : typeStyles.EventTypeGreyContainer
          }
          onClick={() => {
            setEventInputs((eventInputs) => ({
              ...eventInputs,
              eventType: EVENT_TYPES.SPECIFIC_FUNDRAISING,
            }))
            setActiveButton(2)
          }}
        >
          <Stack
            direction={'column'}
            spacing={1}
            className={
              !activeButton || activeButton == 2
                ? typeStyles.EventTypeFundTextContainer
                : typeStyles.EventTypeGreyTextContainer
            }
          >
            <div
              className={
                !activeButton || activeButton == 2
                  ? typeStyles.EventTypeFundName
                  : typeStyles.EventTypeGreyName
              }
            >
              Fundraising - Make or Break
            </div>
            <div
              className={
                !activeButton || activeButton == 2
                  ? typeStyles.EventTypeFundDescription
                  : typeStyles.EventTypeGreyDescription
              }
            >
              Contribute any amount; minimum goal required
            </div>
          </Stack>
          <LocalAtmIcon
            className={
              !activeButton || activeButton == 2
                ? typeStyles.EventTypeFundIcon
                : typeStyles.EventTypeGreyIcon
            }
          />
        </Stack>

        <Stack
          spacing={1}
          direction="row"
          className={
            !activeButton || activeButton == 3
              ? typeStyles.EventTypeGeneralFundContainer
              : typeStyles.EventTypeGreyContainer
          }
          onClick={() => {
            setEventInputs((eventInputs) => ({
              ...eventInputs,
              eventType: EVENT_TYPES.GENERAL_FUNDRAISING,
            }))
            setActiveButton(3)
          }}
        >
          <Stack
            direction={'column'}
            spacing={1}
            className={
              !activeButton || activeButton == 3
                ? typeStyles.EventTypeGeneralFundTextContainer
                : typeStyles.EventTypeGreyTextContainer
            }
          >
            <div
              className={
                !activeButton || activeButton == 3
                  ? typeStyles.EventTypeGeneralFundName
                  : typeStyles.EventTypeGreyName
              }
            >
              Fundraising - Anything Helps
            </div>
            <div
              className={
                !activeButton || activeButton == 3
                  ? typeStyles.EventTypeGeneralFundDescription
                  : typeStyles.EventTypeGreyDescription
              }
            >
              Contribute any amount; no minimums
            </div>
          </Stack>
          <SavingsIcon
            className={
              !activeButton || activeButton == 3
                ? typeStyles.EventTypeGeneralFundIcon
                : typeStyles.EventTypeGreyIcon
            }
          />
        </Stack>
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        width={'100%'}
        justifyContent={'center'}
      >
        <Button
          sx={{
            mt: 1,
            color: 'rgba(92, 92, 92, 1)',
            backgroundColor: '#2E2E2E',
            textTransform: 'capitalize',
            borderRadius: '10px',
            border: '1px solid rgba(92, 92, 92, 1)',
            marginTop: '10px',
            width: '30%',
            minWidth: '116px',
          }}
          onClick={() => {
            setCurrentScreen(CreateEventPages.createEvent)
          }}
        >
          Back
        </Button>
        <Button
          onClick={() => {
            console.log(eventInputs)
            if (!eventInputs.eventType) {
              setOpenSnackBar(true)
              return
            }
            setCurrentScreen(CreateEventPages.goals)
          }}
          variant="contained"
          sx={{
            mt: 1,
            color: '#f1f1f1',
            backgroundColor: '#2a4fff',
            textTransform: 'capitalize',
            borderRadius: '10px',
            marginTop: '10px',
            width: '30%',
            minWidth: '116px',
          }}
        >
          Next
        </Button>
      </Stack>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert
          severity="error"
          onClose={() => setOpenSnackBar(false)}
          variant="filled"
        >
          {'Please select an event type.'}
        </Alert>
      </Snackbar>
    </Stack>
  )
}

const GoalHeaderComponent = (props) => {
  const { eventType } = props

  switch (eventType) {
    case EVENT_TYPES.DYNAMIC:
      return (
        <Stack
          spacing={1}
          direction="row"
          className={goalStyles.DynamicGoalHeaderContainer}
        >
          <div className={goalStyles.DynamicGoalHeader}>Split Fixed Cost</div>
          <CommitIcon className={goalStyles.DynamicGoalIcon} />
        </Stack>
      )
    case EVENT_TYPES.STATIC:
      return (
        <Stack
          spacing={1}
          direction="row"
          className={goalStyles.StaticGoalHeaderContainer}
        >
          <div className={goalStyles.StaticGoalHeader}>
            Fixed Price Per Person
          </div>
          <ConfirmationNumberIcon className={goalStyles.StaticGoalIcon} />
        </Stack>
      )
    case EVENT_TYPES.GENERAL_FUNDRAISING:
      return (
        <Stack
          spacing={1}
          direction="row"
          className={goalStyles.GeneralFundraisingGoalHeaderContainer}
        >
          <div className={goalStyles.GeneralFundraisingGoalHeader}>
            Fundraising - Anything Helps
          </div>
          <SavingsIcon className={goalStyles.GeneralFundraisingGoalIcon} />
        </Stack>
      )
    case EVENT_TYPES.SPECIFIC_FUNDRAISING:
      return (
        <Stack
          spacing={1}
          direction="row"
          className={goalStyles.SpecificFundraisingGoalHeaderContainer}
        >
          <div className={goalStyles.SpecificFundraisingGoalHeader}>
            Fundraising - Make or Break
          </div>
          <LocalAtmIcon className={goalStyles.SpecificFundraisingGoalIcon} />
        </Stack>
      )
    default:
      break
  }
}

const GoalsScreen = (props) => {
  const { eventInputs, updateSetEvents, setCurrentScreen, submitEvent } = props
  const [openSnackBar, setOpenSnackBar] = React.useState(false)
  const [validationError, setValidationError] = React.useState('')
  const [minimumPeopleError, setMinimumPeopleError] = React.useState(null)
  const [maximumPeopleError, setMaximumPeopleError] = React.useState(null)
  const [goalAmountError, setGoalAmountError] = React.useState(null)
  const [staticPricePerPersonError, setStaticPricePerPersonError] =
    React.useState(null)
  const navigate = useNavigate()

  return (
    <>
      <Stack
        direction={'column'}
        alignItems={'center'}
        alignSelf={'center'}
        className={styles.CreateEventForm}
      >
        <GoalHeaderComponent eventType={eventInputs.eventType} />
        {(function () {
          switch (eventInputs.eventType) {
            case EVENT_TYPES.DYNAMIC:
              return (
                <DynamicEventGoals
                  updateSetEvents={updateSetEvents}
                  eventInputs={eventInputs}
                  minimumPeopleError={minimumPeopleError}
                  setMinimumPeopleError={setMinimumPeopleError}
                  maximumPeopleError={maximumPeopleError}
                  setMaximumPeopleError={setMaximumPeopleError}
                  goalAmountError={goalAmountError}
                  setGoalAmountError={setGoalAmountError}
                />
              )
            case EVENT_TYPES.STATIC:
              return (
                <StaticEventGoals
                  eventInputs={eventInputs}
                  updateSetEvents={updateSetEvents}
                  minimumPeopleError={minimumPeopleError}
                  setMinimumPeopleError={setMinimumPeopleError}
                  maximumPeopleError={maximumPeopleError}
                  setMaximumPeopleError={setMaximumPeopleError}
                  staticPricePerPersonError={staticPricePerPersonError}
                  setStaticPricePerPersonError={setStaticPricePerPersonError}
                />
              )
            case EVENT_TYPES.GENERAL_FUNDRAISING:
              return (
                <GeneralFundraisingEventGoals
                  updateSetEvents={updateSetEvents}
                  eventInputs={eventInputs}
                  goalAmountError={goalAmountError}
                  setGoalAmountError={setGoalAmountError}
                />
              )
            case EVENT_TYPES.SPECIFIC_FUNDRAISING:
              return (
                <SpecificFundraisingEventGoals
                  updateSetEvents={updateSetEvents}
                  eventInputs={eventInputs}
                  goalAmountError={goalAmountError}
                  setGoalAmountError={setGoalAmountError}
                />
              )
            default:
              break
          }
        })()}
        <Stack
          direction="row"
          width={'100%'}
          justifyContent={'center'}
          sx={{ marginTop: '15px' }}
        >
          <Button
            sx={{
              mt: 1,
              color: 'rgba(92, 92, 92, 1)',
              backgroundColor: '#2E2E2E',
              textTransform: 'capitalize',
              marginRight: '25px',
              borderRadius: '10px',
              border: '1px solid rgba(92, 92, 92, 1)',
              width: '30%',
              minWidth: '116px',
            }}
            onClick={() => {
              setCurrentScreen(CreateEventPages.eventType)
            }}
          >
            Back
          </Button>
          <Button
            onClick={async () => {
              const validErrMsg = validateEventGoals(
                eventInputs,
                updateSetEvents
              )
              if (validErrMsg) {
                setValidationError(validErrMsg)
                setOpenSnackBar(true)
                return
              }
              const newEvent = await submitEvent(eventInputs)
              if (newEvent) navigate(`/successfulEvent?eventId=${newEvent.id}`)
            }}
            id="goals-screen-create-event-btn"
            variant="contained"
            pointerEvent
            sx={{
              mt: 1,
              color: '#f1f1f1',
              backgroundColor: '#2a4fff',
              textTransform: 'capitalize',
              borderRadius: '10px',
              width: '30%',
              minWidth: '116px',
              textWrap: 'nowrap',
            }}
          >
            Create Campaign
          </Button>
        </Stack>
      </Stack>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert
          severity="error"
          onClose={() => setOpenSnackBar(false)}
          variant="filled"
        >
          {validationError}
        </Alert>
      </Snackbar>
    </>
  )
}

const EventCreated = (props) => {
  return <div>EventCreated</div>
}

const EventCreatedModal = (props) => {
  const { open, handleClose, createdEvent, setSelectedEventType } = props
  const navigate = useNavigate()

  const eventLink = generateEventLink(createdEvent?.id)
  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          handleClose()
          setSelectedEventType(null)
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            border: '1px solid black',
            height: '50%',
            width: '600px',
            background: 'white',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: '45px 10px',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center',
          }}
        >
          <AddTaskIcon fontSize="large" style={{ color: 'green' }} />
          <h2>Event Created!</h2>
          <p>Share event:</p>
          <p>
            {eventLink}{' '}
            <ContentCopyIcon
              fontSize="small"
              style={{ cursor: 'pointer' }}
              onClick={() => navigator.clipboard.writeText(eventLink)}
            />
          </p>
          <Button onClick={() => navigate('/active')}>
            View My Active Events
          </Button>
        </div>
      </Modal>
    </>
  )
}
export default CreateEventContainer
